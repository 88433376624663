<template>
  <v-card flat style="background-color: #F2F2F2" :style="$vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'">
    <div v-if="this.$route.query.id || detail.all">
      <v-toolbar flat class="pl-4" style="cursor:pointer" @click="detail={stats: {shortUrls: []}}; $router.push({ name: 'CampaignDetail', params: {index: parseInt($route.params.index)}, query: {tab: 1}})">
        <v-icon>chevron_left</v-icon> <span class="ml-5">목록보기</span>
      </v-toolbar>
      <div v-if="detail.all">
        <naverAllReport v-if="(campaign.channel || 'naver') === 'naver' || campaign.naver" :campaign="detail.campaign || campaign" :propositions="propositionsWithIndex" :stats="stats"></naverAllReport>
        <youtubeAllReport v-else-if="campaign.channel === 'youtube' || campaign.youtube" :campaign="detail.campaign || campaign" :propositions="propositionsWithIndex" :stats="stats"></youtubeAllReport>
      </div>
      <div v-else>
        <naverReportDetail v-if="(campaign.channel || 'naver') === 'naver' || campaign.naver" :detail.sync="detail" :viewChartData="viewChartData" :naverLinkChartData="naverLinkChartData" :shortUrlViews="_.find(stats.propositions, {id: detail.id})?.shortUrl?.views || 0"></naverReportDetail>
        <youtubeReportDetail v-else-if="campaign.channel === 'youtube' || campaign.youtube" :campaign="detail.campaign || campaign" :detail.sync="detail" :viewChartData="viewChartData" :youtubeLikeChartData="youtubeLikeChartData"></youtubeReportDetail>
      </div>
    </div>
    <div v-else>
      <naverReportList v-if="(campaign.channel || 'naver') === 'naver' || campaign.naver" :campaign="detail.campaign || campaign" :propositions="_.cloneDeep(propositionsWithIndex)" :detail.sync="detail" :stats="stats"></naverReportList>
      <youtubeReportList v-else-if="campaign.channel === 'youtube' || campaign.youtube" :campaign="detail.campaign || campaign" :propositions="_.cloneDeep(propositionsWithIndex)" :detail.sync="detail" :stats="stats"></youtubeReportList>
    </div>
  </v-card>
</template>

<script>
  import naverReportList from '@/components/campaign/detail/report/naver/NaverReportList'
  import youtubeReportList from '@/components/campaign/detail/report/youtube/YoutubeReportList'
  import naverAllReport from '@/components/campaign/detail/report/naver/NaverAllReport'
  import youtubeAllReport from '@/components/campaign/detail/report/youtube/YoutubeAllReport'
  import naverReportDetail from '@/components/campaign/detail/report/naver/NaverReportDetail'
  import youtubeReportDetail from '@/components/campaign/detail/report/youtube/YoutubeReportDetail'

  export default {
    name: "Report",
    props: ['campaign', 'propositions'],
    components: {
      naverReportList,
      youtubeReportList,
      naverAllReport,
      youtubeAllReport,
      naverReportDetail,
      youtubeReportDetail
    },
    data() {
      return {
        stats: {},
        detail: {
          stats: {shortUrls: []},
          all: false
        },
        viewChartData: [],
        naverLinkChartData: [],
        youtubeLikeChartData: [],
        channels: [],
        categories: [
          {name: '음식', icon: 'mdi-food', sub: ['레스토랑', '레시피', '맛집', '술집', '식품', '음식점', '카페']},
          {name: '뷰티', icon: 'mdi-lipstick', sub: ['네일샵', '다이어트', '미용실', '뷰티정보', '의류/잡화', '패션정보', '화장품']},
          {name: '문화', icon: 'mdi-movie-open', sub: ['도서/팬시', '엔터테인먼트']},
          {name: '정보', icon: 'mdi-lightbulb-on', sub: ['교육', '디자인/편집', '마케팅/광고', '부동산', '의학/병원', '자동차', '재테크', '정보공유-기타', '제품-기타', '취업/창업', 'IT/전자기기']},
          {name: '생활', icon: 'mdi-sofa-single', sub: ['리빙/가구', '생활용품', '스포츠/레저/취미', '웨딩', '장난감', '출산/육아']},
          {name: '여행', icon: 'mdi-airballoon', sub: ['여행/숙박']},
          {name: '게임', icon: 'mdi-gamepad-square', sub: ['게임']},
          {name: '일상', icon: 'mdi-home-heart', sub: ['반려동물', '일상']},
        ],
        totalCount: 0
      }
    },
    computed: {
      propositionsWithIndex() {
        let propositions = this.propositions.filter(item => (item.status >= 32 && item.status < 50))
        if (this.channels.length > 0) {
          propositions = propositions.filter((items) => {
            return (this.channels.some((channel) => {
              return (items.taggableType === ['naver', 'insta', 'youtube'][channel]);
            }));
          })
        }
        propositions = this._.orderBy(propositions, ['responsedAt'], ['desc']);
        return propositions.map((items, index) => ({
          ...items,
          index: index
        }));
      },
      mainCategories() {
        if (this.detail) {
          let mainCategories = {};
          this._.toPairs((this.detail.Influencer || {}).categories).map((category) => {
            this.categories.forEach((maincategory) => {
              if (maincategory.sub.indexOf(category[0]) > -1) {
                mainCategories[maincategory.name] = mainCategories[maincategory.name] || 0;
                mainCategories[maincategory.name] += category[1];
              }
            });
          });
          return mainCategories;
        }
        else {
          return {};
        }
      },
      viewCount() {
        return this._.sumBy(this.propositionsWithIndex, 'views');
      }
    },
    watch: {
      propositionsWithIndex(value) {
        this.updatePropositionData(value);
      },
      async detail(value) {
        await this.updateDetail(value);
      }
    },
    methods: {
      async updatePropositionData(value) {
        let stats = await this.axios.get('/campaign/report?id=' + this.campaign.id);
        this.stats = stats.data;

        value.map(async (proposition) => {
          if (proposition.taggableType === 'naver') {
            let stats = this._.find(this.stats.propositions, {id: proposition.id});
            proposition.keywordRankHistories = stats.keywordRankHistories;
            proposition.viewHistories = stats.viewHistories;
            let today = this.$moment();
            let keywords = proposition.keywordRankHistories.filter((history) => {
              let historyDate = this.$moment(history.date).tz('Asia/Seoul');
              return (today.format('YYYY-MM-DD') === historyDate.format('YYYY-MM-DD'));
            });
            proposition.keywords = this._.sortBy(keywords, ['rank']);
          }
          else if (proposition.taggableType === 'youtube') {
            let stats = this._.find(this.stats.propositions, {id: proposition.id});
            proposition.viewHistories = stats.viewHistories;
          }

          this.propositionsWithIndex = await Promise.all(value.map(async (proposition) => {
            if (proposition.Influencer) {
              let metadata = await this.axios.get('/utils/metadata?url=' + proposition.review);
              proposition.Influencer.metadata = metadata.data;
            }
            return proposition;
          }));

          this.$forceUpdate();
        });
      },
      async updateDetail(value) {
        this.detail = value;
        if (this.detail && !this.detail.all) {
          if (this.detail.Influencer) {
            let metadata = await this.axios.get('/utils/metadata?url=' + this.detail.review);
            this.detail.Influencer.metadata = metadata.data;
          }

          this.viewChartData = [];
          this.naverLinkChartData = [];
          this.youtubeLikeChartData = [];

         const reviewRegisteredAtTime = this.$moment(this.detail.reviewRegisteredAt).tz('Asia/Seoul');
         const viewHistoriesData = this._.compact(this.detail['viewHistories'].map((history) => {
              return {
                x: history.date || history.crawledAt,
                y: history.views || 0
              }
          }))
          .filter(({ x }) => {
            const xTime = this.$moment(x).tz('Asia/Seoul');
            return reviewRegisteredAtTime.isSameOrBefore(xTime, 'day');
          });
          this.viewChartData.push({
            label: '',
            data: viewHistoriesData,
            backgroundColor: 'rgba(189, 19, 40, .7)',
            hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
            borderColor: 'rgba(189, 19, 40, .7)',
            borderWidth: 2,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBorderWidth: 0,
            fill: false,
            showLine: true,
            // pointHitRadius: 100
          });
          this.naverLinkChartData.push({
            label: '',
            data: this._.compact(this.detail['viewHistories'].map((history) => {
              return {
                x: history.date,
                y: history.clicks || 0
              }
            })),
            backgroundColor: 'rgba(189, 19, 40, .7)',
            hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
            borderColor: 'rgba(189, 19, 40, .7)',
            borderWidth: 2,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBorderWidth: 0,
            fill: false,
            showLine: true,
            // pointHitRadius: 100
          });
          this.youtubeLikeChartData.push({
            label: '',
            data: this._.compact(this.detail['viewHistories'].map((history) => {
              return {
                x: history.date,
                y: history.likes + history.comments
              }
            })),
            backgroundColor: 'rgba(189, 19, 40, .7)',
            hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
            borderColor: 'rgba(189, 19, 40, .7)',
            borderWidth: 2,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBorderWidth: 0,
            fill: false,
            showLine: true,
            // pointHitRadius: 100
          });
        }
      }
    },
    created() {
    },
    mounted() {
      this.updatePropositionData(this.propositionsWithIndex);
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight
      })
    }
  };
</script>

<style scoped lang="scss">
  .listwidth {
    width: 100%;
    @media(max-width: 1080px) {
      width: 100vw;
    }
  }
</style>